@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/bank-gothic');
body {
  overflow-x: hidden;

}
@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(90deg) scale(0.8);
  }
  80% {
      transform: rotate(180deg) scale(0.5);
    }
  100% {
    transform: rotate(360deg) scale(0);
  }
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 7s;
  transition-timing-function: ease;
}



.animate-spin {
  animation: spin 2s linear infinite;
}
* {
  margin: 0;
  padding: 0; 
}


